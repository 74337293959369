import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';

import MediaTextBoxComponent
  from '@shipae/components-sandbox/component/media-text-box';

import {
  MediaTextBoxWidget as IProps,
} from 'infrastructure/gatsby/types/media-text-box';
import gloabalReactMdOptions from '../shared/react-makdown-options';

const Container = styled.section`
`;

const MediaTextBox: React.FC<IProps> = ({
  sectionId,
  backgroundImage,
  text,
  boxPosition,
}) => (
  <Container
    id={sectionId}
    data-test="section-media-text-box"
  >
    <MediaTextBoxComponent
      backgroundImage={(
        <GatsbyImage
          fluid={backgroundImage.localFile?.childImageSharp?.fluid || []}
          alt={backgroundImage.alternativeText || ''}
        />
      )}
      boxPosition={boxPosition}
    >
      <div>
        <ReactMarkdown options={gloabalReactMdOptions}>
          {text || ''}
        </ReactMarkdown>
      </div>
    </MediaTextBoxComponent>
  </Container>
);

export default MediaTextBox;
